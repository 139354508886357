import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppFilterPipeModule} from "../_filters/app.filter-pipe.module";
import {MaterialModule} from "../material.module";
import {QueryBuilderModule} from "angular2-query-builder";
/* Components */
import {ModalComponent} from "./modal.component";
import {ConfirmDialogComponent} from "./confirm-dialog/confirm-dialog.component";
import {MatChipsModule} from "@angular/material/chips";
import {TableComponent} from "./table/table.component";
import { NgApexchartsModule } from 'ng-apexcharts';

@NgModule({
    declarations: [
        ModalComponent, ConfirmDialogComponent, TableComponent
    ],
    imports: [
        CommonModule,
        NgApexchartsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        AppFilterPipeModule,
        MatChipsModule,
        MatChipsModule,
        QueryBuilderModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ModalComponent, TableComponent,
        ConfirmDialogComponent
    ]
})

export class SharedModule {
}
