import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, TitleStrategy } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';
import { CommonService } from 'app/_services/common.services';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BaseRequestService } from 'app/_services/base.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {default as _rollupMoment} from 'moment';
import * as _moment from 'moment';
const moment = _rollupMoment || _moment;
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { ModalService } from 'app/_services/modal.service';

@Component({
    selector     : 'enterprise-layout',
    templateUrl  : './enterprise.component.html',
    encapsulation: ViewEncapsulation.None
})
export class EnterpriseLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    colfilterUpdate = new Subject<any>();
    shiftinterval:any
    user: User;
    showadd:boolean=false;
    buttontext:string=''
    currenttab:any={};
    ro:any={};
    form=this.fb.group({
        dash:['RO'],
        search:['']
      })

    formalerts = 
    this.fb.group({
      dash:['Open'],
    })
    columns:any=[];
    colfilter:any={};
    refreshed = 0;
    client:any='';
    shifts:any=[];
    /**
     * Constructor
     */
    range = new FormGroup({
        start: new FormControl(),
        end: new FormControl()
      });
    /**
     * Constructor
     */
    constructor(
        private fb: FormBuilder,
        private _activatedRoute: ActivatedRoute,
        private _router: Router, public modalService: ModalService,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private commonService:CommonService
    )
    {
        this.commonService.addText.subscribe(values =>{
            this.buttontext = values
          })
          this.commonService.addChange.subscribe(values =>{
            this.showadd = values
          })

          this.commonService.columns.subscribe(values =>{
            this.columns = values
          })

          this.commonService.searchdata.subscribe(values =>{
            this.colfilter = values
          })

          this.colfilterUpdate.pipe(
            debounceTime(500),
            distinctUntilChanged())
            .subscribe(value => {
                this.commonService.searchfiler(this.colfilter)
            });
    }

    // // -----------------------------------------------------------------------------------------------------
    // // @ Accessors
    // // -----------------------------------------------------------------------------------------------------

    // /**
    //  * Getter for current year
    //  */
    // get currentYear(): number
    // {
    //     return new Date().getFullYear();
    // }

    // // -----------------------------------------------------------------------------------------------------
    // // @ Lifecycle hooks
    // // -----------------------------------------------------------------------------------------------------

    // /**
    //  * On init
    //  */
    // ngOnInit(): void
    // {
    //     // Subscribe to navigation data
    //     this._navigationService.navigation$
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe((navigation: Navigation) => {
    //             this.navigation = navigation;
    //         });

    //     // Subscribe to media changes
    //     this._fuseMediaWatcherService.onMediaChange$
    //         .pipe(takeUntil(this._unsubscribeAll))
    //         .subscribe(({matchingAliases}) => {

    //             // Check if the screen is small
    //             this.isScreenSmall = !matchingAliases.includes('md');
    //         });
    // }

    // /**
    //  * On destroy
    //  */
    // ngOnDestroy(): void
    // {
    //     // Unsubscribe from all subscriptions
    //     this._unsubscribeAll.next(null);
    //     this._unsubscribeAll.complete();
    // }

    // // -----------------------------------------------------------------------------------------------------
    // // @ Public methods
    // // -----------------------------------------------------------------------------------------------------

    // /**
    //  * Toggle navigation
    //  *
    //  * @param name
    //  */
    // toggleNavigation(name: string): void
    // {
    //     // Get the navigation
    //     const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    //     if ( navigation )
    //     {
    //         // Toggle the opened status
    //         navigation.toggle();
    //     }
    // }



    columnFilter(key,value){
        this.colfilterUpdate.next(key)
    //    this.commonService.searchfiler(this.colfilter)
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        console.log("dash", this.formalerts)
        this.form.get('dash').valueChanges.subscribe(selectedValue  => {
            this.commonService.dash(selectedValue)
        })
        this.formalerts.get('dash').valueChanges.subscribe(selectedValue  => {
          this.commonService.alertss(selectedValue)
        })

        

        this._navigationService.navigation$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((navigation: Navigation) => {
                this.navigation = navigation;
            });

        // Subscribe to the user service
        // this._userService.user$
        //     .pipe((takeUntil(this._unsubscribeAll)))
        //     .subscribe((user: User) => {
        //         this.user = user;
        //         this.user = JSON.parse(localStorage.getItem('user'))
        //         // this.user.ro_data=["187648"]
        //         if(this.user && this.user.ro_data && this.user.ro_data.length>0){
        //             // this.fetchro()
        //             // this.fetchshifts()
        //         }
        //     });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
        
        this.commonService.currenttab.subscribe(values =>{
            this.currenttab = values
        })
        if(window.location.href.includes('ioml')){
            this.client = 'ioml'
        }
        else{
            this.client = 'iocl'
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    columnFilterrangelsr(val: any, col: any): void {
        var searchedColName = col;
        // console.log("value", this.start)
        var dateDoc:any={};
        console.log("value", this.range.value)
          dateDoc.startDate = this.range.controls.start.value
          dateDoc.endDate = this.range.controls.end.value
        if(dateDoc.startDate !== '' && dateDoc.endDate !== '' && dateDoc.endDate !== null && dateDoc.startDate !== null ){
          const gtr = (dateDoc.startDate).format('DD-MMM-YYYY')
          const lsr = (dateDoc.endDate).format('DD-MMM-YYYY')
         const values={"$gte":gtr, "$lte":lsr}
         dateDoc={'startDate':'', 'endDate':''}
       
        this.colfilterUpdate.next({ value: values, col });}
      }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    add(type){
        this.commonService.create({'create':this.showadd, 'button':this.buttontext, 'type': type})
    }

    search(){

    }

    refresh(){
        console.log("refreshed")
        this.refreshed = this.refreshed+1
        this.commonService.refresh(this.refreshed)

      //   const confirmation = this._fuseConfirmationService.open({
      //     title  : `Start Dealer Shift`,
      //     message: `Do you wish to stop existing afternoon shift and start Dealer_6_30 shift?`,
      //     actions: {
      //         confirm: {
      //             label: 'Yes'
      //         }
      //     }
      // });

      // // Subscribe to the confirmation dialog closed action
      // confirmation.afterClosed().subscribe((result) => {
      //     if(result === 'confirmed'){
      //       console.log("yes")
      //     }    
      // })
    }
}
